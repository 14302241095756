@media screen and (max-width: 530px) {
  header .intro-text {
    padding-top: 270px;
    padding-bottom: 200px;
    width: 100%;
    text-align: center;
  }
  .intro h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  #menu a.navbar-brand {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
  }
  #features {
    /* padding: 20px; */
    /* width: 111%; */
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    /* width: 111%; */
  }

  #portfolio {
    /* width: 110%; */
  }
}